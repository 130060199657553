import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"
import NotificationModal from "../../common/NotificationModal"
import {
  onUserSignInRequest,
  onUserSignInClick,
  onUserSignOut,
  closeMessage,
  clearStationState,
  clearUserState,
  resetAllReducers,
} from "../../../actions"

import "./style.scss"

class LoginScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      mobile: "",
      passw: "",
      validField: {
        mobile: {
          type: "mobile",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        passw: {
          type: "text",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
      },
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated } = this.props
    if (authenticated) {
      this.props.onUserSignOut()
    }
    this.state = this.INIT_STATE
    this.props.resetAllReducers()
    this.props.clearStationState()
  }

  componentDidUpdate(prevProps) {
    const { userData } = this.props

    if (userData) {
      let isNeedChangePassword =
        userData.isPasswordTemporary || !userData.isStrongPassword

      if (isNeedChangePassword) {
        this.props.history.push("ChangePasswordScreen")
        return
      }

      switch (userData.orderTypeGroup) {
        case "5":
        case "6":
          this.props.history.push("StationSelectScreen")
          break
        case "3":
        case "4":
          this.props.history.push("OrdersListScreen")
          break
        case "2":
          this.props.history.push("StationsScreen")
          break
        case "1":
          if (userData.role === "Exelot") {
            this.props.history.push("StationsScreen")
          } else {
            this.props.history.push("OrdersListScreen")
          }
          break
        default:
          this.props.clearUserState()
          this.props.history.push("/")
          alert(`תפקיד זה לא מוגדר במערכת`)
      }
    }
  }

  componentWillUnmount() {
    this.state = this.INIT_STATE
  }

  setValue(inputName, e) {
    switch (inputName) {
      case "mobile":
        this.setState({ mobile: e.target.value })
        break
      case "passw":
        this.setState({ passw: e.target.value })
        break
      default:
        break
    }
  }

  onInputFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onClickLoginButton() {
    const { validField, mobile, passw } = this.state
    this.validateInputValue("mobile", mobile)
    this.validateInputValue("passw", passw)
    const isValid =
      validField.passw.isChecked &&
      validField.passw.isValid &&
      validField.mobile.isChecked &&
      validField.mobile.isValid
    if (!isValid) {
      return
    }
    //LOGIN
    this.props.onUserSignInRequest()
    this.props.onUserSignInClick(mobile, passw)
  }

  onMessageClickOk(e) {
    this.props.closeMessage()
  }

  render() {
    const { validField, mobile, passw } = this.state
    const { isLoading, showMessage } = this.props

    if (isLoading) {
      return (
        <div>
          <Title text="כניסה למערכת" />
          <Loader show={isLoading} />
        </div>
      )
    }
    return (
      <div className="login">
        <Title text="כניסה למערכת" />
        <form>
          <InputField
            isFocused={true}
            show={true}
            value={this.state.mobile}
            placeholder="מספר טלפון"
            mandatory={true}
            showValidation={validField.mobile.isChecked}
            showValidationOk={validField.mobile.isValid}
            showValidationError={!validField.mobile.isValid}
            validationErrorMessage="מספר טלפון לא תקין"
            onFocus={(e) => this.onInputFocus("mobile")}
            onBlur={(e) => this.validateInputValue("mobile", e.target.value)}
            onChange={(e) => this.setValue("mobile", e)}
            maxLength={10}
          />
          <InputField
            show={true}
            value={this.state.passw}
            iconStyle=""
            placeholder="סיסמא"
            mandatory={true}
            cc={validField.passw.isChecked}
            showValidationOk={validField.passw.isValid}
            showValidationError={!validField.passw.isValid}
            validationErrorMessage="סיסמא לא תקינה"
            onFocus={(e) => this.onInputFocus("passw")}
            onBlur={(e) => this.validateInputValue("passw", e.target.value)}
            onChange={(e) => this.setValue("passw", e)}
            type="password"
            maxLength={20}
          />
          <div className="buttons-wrapper">
            <Button onClick={(e) => this.onClickLoginButton()}>כניסה</Button>
          </div>
          <NotificationModal
            show={showMessage}
            text={
              <div className="login-error">
                <div className="error-title">שגיאת התחברות</div>
                <div className="error-text">אנא בדוק את הפרטים ונסה שוב</div>
              </div>
            }
            onOkClick={(e) => this.onMessageClickOk(e)}
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const { authenticated, userData, isLoading, showMessage } = users

  return {
    authenticated,
    userData,
    isLoading,
    showMessage,
  }
}

export default connect(mapStateToProps, {
  onUserSignInRequest,
  onUserSignInClick,
  onUserSignOut,
  closeMessage,
  clearStationState,
  clearUserState,
  resetAllReducers,
})(LoginScreen)
