import React, { Component } from "react"
import { connect } from "react-redux"

import { setOrdersSearch, clearOrdersSearch } from "../../../actions"

import { Button } from ".."
import InputFiled from "../InputField"
import { isValidField } from "../../../utils/FieldsValidation"
import NotificationModal from "../NotificationModal"
import "./style.scss"

class OrderSearchFilterer extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      defaultPackageNumber: "",

      showDateCantBeNull: false,
      search: {
        packageNumber: null,
      },

      searchData: {
        packageNumber: "",
      },

      validField: {
        packageNumber: {
          type: "packageNumber",
          isRequired: false,
          isChecked: false,
          compareMinValue: 3,
          compareMaxValue: 90,
          isValid: false,
        },
      },
    }

    this.state = { ...this.INIT_STATE }

    this.setDefaultsAndSearch = this.setDefaultsAndSearch.bind(this)
    this.cleanSearch = this.cleanSearch.bind(this)
  }

  componentDidMount() {
    this.setState({
      searchData: {
        packageNumber: "",
      },
    })
  }

  componentWillReceiveProps(newProps) {
    let { searchData } = newProps

    const isEmptySearch =
      JSON.stringify(searchData) === JSON.stringify(this.INIT_STATE.searchData)
    const isDataChanged =
      JSON.stringify(searchData) !== JSON.stringify(this.state.searchData)

    if (isDataChanged) {
      console.log("searchData is changed!!!")
      this.setState({
        searchData,
      })
    }

    if (isEmptySearch) {
      this.props.setOrdersSearch(searchData)
    }
  }

  setDefaultsAndSearch(searchData) {
    this.setState({
      searchData,
    })
  }

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) {
      return
    }

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field
    console.log(inputName + ": ")
    console.log(field)
    this.setState({
      ...this.state,
      validField,
    })
  }

  updateActiveField(fieldName, value) {
    //TODO VALIDATION
    value = value.trim()
    this.validateInputValue(fieldName, value)
    const { validField } = this.state
    let isValid = true

    if (validField[fieldName] !== undefined) {
      isValid = validField[fieldName].isChecked && validField[fieldName].isValid
    }

    //if (!isValid) return

    let { searchData } = this.state
    searchData[fieldName] = value
    searchData.page = 0
    searchData.nextPageKey = null
    this.setState({ searchData })
    this.props.onSearchFilterChanged(searchData)
  }

  searchClick() {
    let { searchData } = this.state

    this.setState({ searchData })
    this.props.onSearchFilterChanged(searchData)
    this.props.searchOrdersClick()
  }
  cleanSearch() {
    this.props.clearOrdersSearch()
    this.setState({
      search: {
        packageNumber: "",
      },
    })
  }

  render() {
    const { isLoadingSearch } = this.props

    const { searchData, validField } = this.state
    const { orderNumber, packageNumber } = searchData

    return (
      <>
        <div className="search-order-filterer ">
          <div className="line-wrapper">
            <InputFiled
              show
              onChange={(e) =>
                this.updateActiveField("packageNumber", e.target.value)
              }
              //onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש לפי מספר חבילה"
              value={packageNumber}
              alt="ניתן לחפש הזמנה לפי מספר חבילה."
              icon="magnifying_glass"
              showValidation={validField["packageNumber"].isChecked}
              showValidationOk={validField["packageNumber"].isValid}
              showValidationError={!validField["packageNumber"].isValid}
              validationErrorMessage="אמור להכיל לפחות 3 מספרים ואותיות באנגלית"
              maxLength={30}
            />
          </div>

          <div className="line-wrapper">
            <div className="search-btn-wrapper">
              <Button
                extraClass={`${isLoadingSearch ? "disabled" : "search-btn"}`}
                onClick={(e) => this.searchClick()}
                tabIndex="0"
                disabled={isLoadingSearch}
              >
                חפש
              </Button>
            </div>
          </div>
        </div>
        <NotificationModal
          type={"warning"}
          title={"התאריך שמנסים לבחור לא תקין"}
          show={this.state.showDateCantBeNull}
          text={" אנא בחר תאריך תקין. לא נתן לרוקן את השדה."}
          onOkClick={() => this.setState({ showDateCantBeNull: false })}
        />
      </>
    )
  }
}

const mapStateToProps = ({ searchOrders }) => {
  const { searchData, isLoadingSearch } = searchOrders

  return {
    searchData,
    isLoadingSearch,
  }
}

export default connect(mapStateToProps, {
  setOrdersSearch,
  clearOrdersSearch,
})(OrderSearchFilterer)
