import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import SelectField from "../../common/SelectField"
import Message from "../../common/Message"
import OrderHistoryModal from "../../common/OrderHistoryModal"
import { getFormatedDate } from "../../../utils/FormatedDate"
import ChangeStationModal from "./ChangeStation/changeStationModal.js"
import TransferOrderModal from "./TransferOrder/transferOrderModal.js"
import NotificationModal from "../../common/NotificationModal"
import OrderDelayModal from "../../common/OrderDelayModal"
import YesNoModal from "../../common/YesNoModal"
import Select from "react-select"
import {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderDetails,
  onGetStations,
  fetchAllStationData,
  onUpdateOrderStation,
  clearOrderStationUpdate,
  updateOrderStatusAndExtentionDate,
  clearOrderStatusUpdate,
  sendReadyInLockerSMS,
  clearSendReadyInLockerSMS,
  onTransferOrder,
  clearTransferOrder,
} from "../../../actions"

import "./style.scss"

class EditOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: null,
      isShowHistory: false,
      deliveryRoles: ["DHL", "Bar", "YDM", "HFD"],
      station: "",
      isShowStations: false,
      isOrderStationUpdated: false,
      showOrderDelayModal: false,
      selectedOrderStatus: null,
      showInLockerSmsConfirmModal: false,
      showTransferOrderModal: false,
      ordersStatusesList: this.generateOrdersStatusesList(
        this.props.order.orderStatus
      ),
    }
  }
  generateOrdersStatusesList(orderStatus) {
    return [
      {
        value: 4,
        label: "מוכן בלוקר",
        isDisabled: !this.isStatusValid(orderStatus, 4),
      },
      {
        value: 5,
        label: "נאסף על ידי לקוח",
        isDisabled: !this.isStatusValid(orderStatus, 5),
      },
      {
        value: 6,
        label: "מבוטל",
        isDisabled: !this.isStatusValid(orderStatus, 6),
      },
      {
        value: 12,
        label: "הוחזר לשולח",
        isDisabled: !this.isStatusValid(orderStatus, 12),
      },
      {
        value: 13,
        label: "סומן לחזרה לשולח",
        isDisabled: !this.isStatusValid(orderStatus, 13),
      },
    ]
  }

  componentDidMount() {
    const { authenticated, history, order, showMessage, ordersStatusesArr } =
      this.props
    if (!authenticated) {
      history.push("/")
    }
    if (!order && !showMessage) {
      if (this.props.orderTypeGroup === "2" && this.props.role == "Exelot") {
        this.props.history.push("/OrdersSearchScreen")
      } else {
        this.props.history.push("/OrdersListScreen")
      }
    }
    this.setState({ order: this.props.order })
    this.props.getOrderDetails(this.props?.order?.id)
    this.props.fetchOrderStatuses()
    this.setState({
      selectedOrderStatus: {
        label: ordersStatusesArr[order.orderStatus],
        value: order.orderStatus,
      },
    })
  }

  componentWillReceiveProps(newProps) {
    const {
      authenticated,
      history,
      orderDetails,
      stationsItemSelectList,
      stationsAll,
      order,
      orderStationUpdatedSuccess,
    } = newProps
    if (!authenticated) {
      history.push("/")
    }
    if (this.state.order !== order) {
      this.setState({ order: order })
    }

    if (stationsAll?.length > 0 && stationsItemSelectList.length == 0) {
      this.props.fetchAllStationData(stationsAll)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      orderStationUpdatedSuccess,
      getOrderDetails,
      order,
      orderStatusUpdateSuccess,
    } = this.props

    if (orderStationUpdatedSuccess && !this.state.isOrderStationUpdated) {
      this.setState({ isOrderStationUpdated: true })
      getOrderDetails(order?.id)
    }
  }

  onClickUpdateOrder() {
    this.props.onStartLoading()
    const { order } = this.props
    order.orderStatus = this.state.selectedOrderStatus.value
    this.props.onUpdateOrder(order)
  }

  onUpdateField(fieldName, value) {
    // const { order } = this.props
    // let updatedOrder = order
    //updatedOrder[fieldName] = value
    this.setState({ selectedOrderStatus: value })
    //this.setState({ order: updatedOrder })
    // this.props.setSelectedOrder(updatedOrder)
  }

  onMessageCkickOk() {
    this.props.closeMessage()
    if (this.props.orderTypeGroup === "2" && this.props.role == "Exelot") {
      this.props.history.push("/OrdersSearchScreen")
    } else {
      this.props.history.push("/OrdersListScreen")
    }
  }

  showOrderHistory() {
    const { order } = this.props
    this.setState({ isShowHistory: true })
  }

  isShowStationsModal() {
    this.props.onGetStations()
    this.setState({ isShowStations: true })
  }

  isShowTransferOrderModal() {
    this.props.onGetStations()
    this.setState({ showTransferOrderModal: true })
  }

  closeOrderHistory() {
    this.setState({ isShowHistory: false })
  }

  isShowLockerData() {
    const { order, orderDetails } = this.props

    if (
      order.orderStatus == 0 ||
      order.orderStatus == 4 ||
      order.orderStatus == 13 ||
      order.orderStatus == 18
    ) {
      return (
        <>
          <InputField
            show={true}
            value={order.currentLocation?.replace(/\D/g, "")}
            labelText="מספר לוקר"
            disabled
          />
          <InputField
            show={true}
            value={
              order.deliveryLoginCode
                ? order.deliveryLoginCode
                : orderDetails?.deliveryLoginCode
            }
            labelText="לוקר קוד"
            disabled
          />
        </>
      )
    }
  }

  isShowChangeStationBtn() {
    const { order, userData } = this.props
    const { deliveryRoles, isShowChangeStationSelect } = this.state
    if (userData?.orderTypeGroup == "2" && order.orderStatus == 3) {
      return (
        <button
          className="btn-change-station"
          onClick={() =>
            this.setState({
              isShowChangeStationSelect: !isShowChangeStationSelect,
            })
          }
        >
          לחץ לשינוי תחנה
        </button>
      )
    }
  }

  updateStationHndler(stationNumber) {
    const { id } = this.props.order
    this.props.onUpdateOrderStation(id, stationNumber)
    this.setState({ isShowStations: false })
  }

  transferOrderHandler(stationId) {
    const { id } = this.props.order
    this.props.onTransferOrder(id, stationId)
    this.setState({ showTransferOrderModal: false })
  }

  isAllowUserChangeStation() {
    const { order, userData } = this.props
    if (userData.orderTypeGroup == "2" && order.orderStatus == 3) {
      return true
    }

    return false
  }

  isAllowUserMoveStation() {
    const { order, userData } = this.props
    if (
      userData.orderTypeGroup == "1" &&
      (order.orderStatus == 4 || order.orderStatus == 13)
    ) {
      return true
    }

    return false
  }

  extendPackageClick() {
    this.setState({
      showOrderDelayModal: true,
    })
  }

  updateInLockerDate(selectedDate) {
    const dateWithTime = new Date(selectedDate)
    dateWithTime.setHours(23)
    dateWithTime.setMinutes(0)
    const unixTimestamp = Math.floor(dateWithTime.getTime() / 1000)
    this.props.updateOrderStatusAndExtentionDate(
      this.state.order?.id,
      4,
      unixTimestamp
    )
  }

  onCloseInLockerDateModal() {
    this.props.getOrderDetails(this.state.order?.id)
    this.props.clearOrderStatusUpdate()
    this.setState({
      showOrderDelayModal: false,
    })
  }

  isStatusValid(currStatus, optionIdx) {
    if (
      (currStatus == 3 && optionIdx != 6) ||
      currStatus == 12 ||
      currStatus == 6 ||
      currStatus == 5 ||
      (currStatus == 18 && optionIdx != 6) ||
      (currStatus == 19 && optionIdx != 6) ||
      (currStatus == 4 && optionIdx != 13 && optionIdx != 5) ||
      (currStatus == 13 && optionIdx != 12 && optionIdx != 5) ||
      (currStatus == 13 && optionIdx != 5)
    ) {
      return false
    }
    return true
  }

  handleInLockerSmsConfirmModal() {
    this.setState({ showInLockerSmsConfirmModal: true })
  }

  inLockerSmsConfirmed() {
    this.props.sendReadyInLockerSMS(this.state.order?.id)
    this.setState({ showInLockerSmsConfirmModal: false })
  }

  render() {
    const {
      isLoading,
      showMessage,
      isError,
      orderDetails,
      userData,
      stationsItemSelectList,
      orderStationUpdatedSuccess,
      orderStationUpdatedFail,
    } = this.props
    const { order, isShowStations, showTransferOrderModal } = this.state
    //console.log("עדכון הזמנה")
    //console.log("isLoading:" + isLoading)
    //console.log("order:")
    //console.log(order)
    if (isLoading || order === null) {
      return (
        <div>
          <Title text="עדכון הזמנה" />
          <Loader show={isLoading} />
          <Message
            show={showMessage}
            isError={isError}
            successText="ההזמנה עודכנה בהצלחה"
            errorText="ההזמנה לא עודכנה"
            onClick={(e) => this.onMessageCkickOk(e)}
          />
        </div>
      )
    }

    const { ordersStatusesArr } = this.props

    return (
      <div className="update-order">
        <Title text="עדכון הזמנה" />
        <div className="btn-header-holder">
          <div onClick={() => this.showOrderHistory()}>היסטוריה של הזמנה</div>
          {this.isAllowUserChangeStation() ? (
            <div onClick={() => this.isShowStationsModal()}>שינוי תחנה</div>
          ) : null}
          {this.isAllowUserMoveStation() ? (
            <div onClick={() => this.isShowTransferOrderModal()}>
              העברה לתחנה אחרת
            </div>
          ) : null}
          {order?.orderStatus == 3 ||
          order?.orderStatus == 4 ||
          order?.orderStatus == 13 ? (
            <div onClick={() => this.extendPackageClick()}>
              {" "}
              הארכת זמן חבילה בתא{" "}
            </div>
          ) : null}
          {order?.orderStatus == 4 ? (
            <div onClick={() => this.handleInLockerSmsConfirmModal()}>
              שלח SMS "מוכן בלוקר"
            </div>
          ) : null}
        </div>
        {isShowStations && stationsItemSelectList.length > 0 ? (
          <ChangeStationModal
            stations={stationsItemSelectList}
            currentStationNumber={orderDetails?.stationNumber}
            onSaveClick={(stationNumber) =>
              this.updateStationHndler(stationNumber)
            }
            onCloseClick={() => this.setState({ isShowStations: false })}
          />
        ) : null}
        {showTransferOrderModal && stationsItemSelectList.length > 0 ? (
          <TransferOrderModal
            stations={stationsItemSelectList}
            currentStationNumber={orderDetails?.stationNumber}
            onSaveClick={(stationId) => this.transferOrderHandler(stationId)}
            onCloseClick={() =>
              this.setState({ showTransferOrderModal: false })
            }
          />
        ) : null}
        <InputField
          show={true}
          value={order.orderNumber}
          placeholder="מספר הזמנה"
          disabled
        />
        <InputField
          show={true}
          value={order.packageNumber}
          placeholder="מספר חבילה"
          disabled
        />
        <InputField
          show={true}
          value={order.mobilePhone}
          placeholder="מספר טלפון"
          disabled
        />
        <InputField
          show={true}
          value={order.firstName}
          placeholder="שם פרטי"
          disabled
        />
        <InputField
          show={true}
          value={order.lastName}
          placeholder="שם משפחה"
          disabled
        />
        {this.isShowLockerData()}
        <div className="select-status">
          <div className="select-wrapper">
            <Select
              classNamePrefix=""
              value={this.state.selectedOrderStatus}
              placeholder={"סטטוס הזמנה"}
              isClearable={true}
              options={this.state.ordersStatusesList}
              onChange={(evt) => this.onUpdateField("orderStatus", evt)}
            />
          </div>
        </div>
        <div className="buttons-wrapper">
          <Button onClick={(e) => this.onClickUpdateOrder()}>עדכן הזמנה</Button>
          <Button
            extraClass="back-btn"
            onClick={(e) => this.props.history.goBack()}
          >
            חזור
          </Button>
        </div>

        <Loader show={isLoading} />
        <OrderHistoryModal
          isShow={this.state.isShowHistory && orderDetails}
          order={orderDetails}
          setViewHistory={() => this.closeOrderHistory()}
          statuses={ordersStatusesArr}
          getFormatedDate={getFormatedDate}
        />
        <OrderDelayModal
          isOpen={this.state.showOrderDelayModal}
          isClose={() => this.setState({ showOrderDelayModal: false })}
          submitDate={(selectedDate) => this.updateInLockerDate(selectedDate)}
          inLockerDate={this.state.order?.inLockerExtentionDate}
        />
        <YesNoModal
          show={this.state.showInLockerSmsConfirmModal}
          title={"שלח SMS"}
          text={"?האם אתה בטוח שברצונך לשלוח הודעה ללקוח"}
          onYesClick={() => this.inLockerSmsConfirmed()}
          onNoClick={() =>
            this.setState({ showInLockerSmsConfirmModal: false })
          }
        />
        <Message
          show={showMessage}
          isError={isError}
          successText="ההזמנה עודכנה בהצלחה"
          errorText="ההזמנה לא עודכנה"
          onClick={(e) => this.onMessageCkickOk(e)}
        />
        <NotificationModal
          show={orderStationUpdatedSuccess}
          title={"עדכון עמדה"}
          text={"עמדה עודכנה בהצלחה"}
          onOkClick={() => this.props.clearOrderStationUpdate()}
        />
        <NotificationModal
          extraClass="error"
          show={orderStationUpdatedFail}
          title={"עדכון עמדה"}
          text={"עדכון עמדה נכשל"}
          onOkClick={() => this.props.clearOrderStationUpdate()}
        />
        <NotificationModal
          show={this.props.orderStatusUpdateSuccess}
          text={"הזמנה נדחתה בהצלחה"}
          onOkClick={() => this.onCloseInLockerDateModal()}
        />
        <NotificationModal
          extraClass="error"
          show={this.props.orderStatusUpdateFail}
          text={"שגיא, הזמנה לא נדחתה "}
          onOkClick={() => this.onCloseInLockerDateModal()}
        />
        <NotificationModal
          extraClass="success"
          show={this.props.sendReadyInLockerSMSSuccess}
          text={"ההודעה נשלחה בהצלחה"}
          onOkClick={() => this.props.clearSendReadyInLockerSMS()}
        />
        <NotificationModal
          extraClass="error"
          show={this.props.sendReadyInLockerSMSFail}
          text={"שגיא, ההודעה לא נשלחה "}
          onOkClick={() => this.props.clearSendReadyInLockerSMS()}
        />
        <NotificationModal
          extraClass="success"
          show={this.props.transferOrderSuccess}
          text={"בקשה להעברת הזמנה לעמדה אחרת התקבלה בהצלחה"}
          onOkClick={() => this.props.clearTransferOrder()}
        />
        <NotificationModal
          extraClass="error"
          show={this.props.transferOrderFail}
          text={" .שגיא, בקשה לא התקבלה "}
          onOkClick={() => this.props.clearTransferOrder()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, orderStatuses, station }) => {
  const { authenticated, userData } = users
  const { orderTypeGroup, role } = userData
  const {
    isLoading,
    order,
    showMessage,
    isError,
    orderDetails,
    orderStationUpdatedSuccess,
    orderStationUpdatedFail,
    orderStatusUpdateSuccess,
    orderStatusUpdateFail,
    sendReadyInLockerSMSSuccess,
    sendReadyInLockerSMSFail,
    transferOrderSuccess,
    transferOrderFail,
  } = orders
  const { ordersStatusesArr, ordersStatuses_SelectList } = orderStatuses
  const { stationsAll, stationsItemSelectList } = station
  return {
    authenticated,
    isLoading,
    showMessage,
    isError,
    order,
    ordersStatusesArr,
    orderDetails,
    userData,
    stationsItemSelectList,
    stationsAll,
    orderStationUpdatedSuccess,
    orderStationUpdatedFail,
    orderStatusUpdateSuccess,
    orderStatusUpdateFail,
    ordersStatuses_SelectList,
    orderTypeGroup,
    sendReadyInLockerSMSSuccess,
    sendReadyInLockerSMSFail,
    transferOrderSuccess,
    transferOrderFail,
    role,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderDetails,
  onGetStations,
  fetchAllStationData,
  onUpdateOrderStation,
  clearOrderStationUpdate,
  updateOrderStatusAndExtentionDate,
  clearOrderStatusUpdate,
  sendReadyInLockerSMS,
  clearSendReadyInLockerSMS,
  onTransferOrder,
  clearTransferOrder,
})(EditOrderScreen)
